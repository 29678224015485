<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="Export"
                  class="ml-1"
              >
                <feather-icon
                    icon="FileIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col md="4" cols="12" class="mb-2">
                <xy-input-button label="日期范围"
                                 type="time"
                                 v-on:change="fromChildren($event,['time'])"
                                 :params="['time']"
                                 :value="condition.time"
                >
                </xy-input-button>
              </b-col>

              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="平台"
                    type="input"
                    v-on:change="fromChildren($event,['companyName','companyId'])"
                    :params="['companyName','companyId']"
                    :value="condition.companyName"
                    modalName="渠道方"
                    placeholder="点击选择平台"
                >
                </xy-input-button>
              </b-col>
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="城市"
                    type="input"
                    v-on:change="fromChildren($event,['areaName','areaId'])"
                    :params="['areaName','areaId']"
                    :value="condition.areaName"
                    modalName="城市"
                    placeholder="点击选择城市"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="店铺名称"
                    type="input"
                    v-on:change="fromChildren($event,['storeName','storeId'])"
                    :params="['storeName','storeId']"
                    :value="condition.storeName"
                    modalName="店铺"
                    placeholder="点击选择店铺"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(cd_id)="data">
          #{{ data.item.cd_id }}
        </template>

        <!-- Column: Type -->
        <template #cell(city)="data">
          {{ data.item.ext.city }}
        </template>

        <template #cell(time)="data">
          {{ data.item.ext.time }}
        </template>

        <template #cell(after_sales_rate)="data">
          {{ data.item.ext.after_sales_rate }}
        </template>

        <template #cell(remain_cargo_damage_rate)="data">
          {{ data.item.ext.remain_cargo_damage_rate }}
        </template>

        <template #cell(customer_return_cargo_damage_rate)="data">
          {{ data.item.ext.customer_return_cargo_damage_rate }}
        </template>

        <template #cell(link_loss_rate)="data">
          {{ data.item.ext.link_loss_rate }}
        </template>

        <template #cell(cargo_damage_rate)="data">
          {{ data.item.ext.cargo_damage_rate }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <!--多菜单-->
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showModal(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

          <b-modal
              id="modal"
              ok-only
              ok-title="确认"
              cancel-title="取消"
              centered
              size="lg"
              title="编辑"
              ref="modal"
              @ok="save"
              @close="refetchData()"
              no-close-on-backdrop
              no-close-on-esc
          >
            <b-row>
              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="平台"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.company_name"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="城市"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.ext.city"
                      readonly
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="店铺名称"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.store_name"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="日期范围"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.ext.time"
                      readonly
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="总结余货损金额"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.remain_cargo_damage_amount"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="总客退货损金额"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.customer_return_cargo_damage_amount"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12" class="mb-1">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="总链路损失金额"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="cargoDamage.link_loss_amount"
                      type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12" class="mb-1"></b-col>
              <b-col md="12" cols="12" class="mb-1">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="附件"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload :theme="'files'"
                                     :attachment_id="'attachments'"
                                     :id="cargoDamage.attachments"
                                     :object_type="'cargo_damage'"
                                     :object_id="cargoDamage.id"
                                     @change="attachUpload"
                  />
                </b-form-group>
              </b-col>
            </b-row>


          </b-modal>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import cargodamageUseList from './cargodamageUseList'
import cargodamageStore from './cargodamageStore'
import Ripple from "vue-ripple-directive";
import XyInputButton from "@/views/components/xy/XyInputButton";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useToast} from "vue-toastification/composition";
import axios from "@/libs/axios";

export default {
  components: {
    XyInputButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('cargodamage/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
    searchByCondition() {
      this.refetchData();
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      advanced_search_modal: false,
      cargoDamage: {ext:{}},
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('cargodamage')) store.registerModule('cargodamage', cargodamageStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('cargodamage')) store.unregisterModule('cargodamage')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const showModal = function (params) {
      this.cargoDamage = params
      this.$refs['modal'].show()
    }

    const save = function() {
      console.log()
      store.dispatch('cargodamage/save', this.cargoDamage).then(res => {
        toast.success('数据已保存!')
        refetchData()
      })
    }

    const attachUpload = function (id, attachment, result) {
      this.cargoDamage.attachments = result
    }

    const Export = function () {
      axios({
            url: '/api/exportexcelfile/exportCargoDamage',
            method:'get',
            params: {
              time:condition.value.time,
              companyId:condition.value.companyId,
              areaId:condition.value.areaId,
              storeId:condition.value.storeId,
            },
            responseType: 'blob',
          }
      ).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], { type: 'application/vnd.ms-excel' })   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "货损报表-"+new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download',filename+'.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    }

    const {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = cargodamageUseList()

    return {
      condition,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      showModal,
      save,
      attachUpload,
      Export,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
