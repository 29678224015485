import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function cargodamageUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'cd_id', label: 'ID', sortable: true },
    { key: 'company_name', label: '平台名称', sortable: true },
    { key: 'city', label: '城市', sortable: true },
    { key: 'store_name', label: '店铺名称', sortable: true },
    { key: 'time', label: '日期范围', sortable: true },
    { key: 'sales_order_price', label: '总GMV', sortable: true },
    { key: 'sales_returnbound_price', label: '总售后扣款金额', sortable: true },
    { key: 'after_sales_rate', label: '总售后率', sortable: true },
    { key: 'remain_cargo_damage_amount', label: '总结余货损金额', sortable: true },
    { key: 'remain_cargo_damage_rate', label: '总结余货损率', sortable: true },
    { key: 'customer_return_cargo_damage_amount', label: '总客退货损金额', sortable: true },
    { key: 'customer_return_cargo_damage_rate', label: '总客退货损率', sortable: true },
    { key: 'link_loss_amount', label: '总链路损失金额', sortable: true },
    { key: 'link_loss_rate', label: '总链路损失率', sortable: true },
    { key: 'cargo_damage_rate', label: '总货损率', sortable: true },
    // { key: 'attachments', label: '附件', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    // { key: 'create_time', label: '创建时间', sortable: true },
    // { key: 'creator', label: '创建人', sortable: true },
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('cd_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('cargodamage/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        time:condition.value.time,
        companyId:condition.value.companyId,
        areaId:condition.value.areaId,
        storeId:condition.value.storeId,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
